import React from 'react';
import './InstructionModal.css';

function InstructionModal({ onClose }) {
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content">
                <h2>Welcome to Find College Parking!</h2>
                <ul>
                    <li>
                        <img src="/img/FCP-circle.png" alt="Custom Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} /> icons are parking spaces available for rent.
                    </li>
                    <li>
                        <img src="/img/FCP-filled.png" alt="Custom Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} /> icons are filled parking spaces.
                    </li>
                    <li>Click on an avaiable space to see more details and contact information.</li>
                    <hr />
                    <li><b>NOTE: Pins are approximate locations based on nearest point of interest.</b></li>
                </ul>
                <button onClick={onClose}>Got it!</button>
            </div>
        </div>
    );
}

export default InstructionModal;